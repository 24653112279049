@import 'styles/mixins';

.threatsComponent {
  width: 100%;
}

.threatsTable {
  display: grid;
  gap: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.threatsRow {
  display: flex;
  align-items: center;
  height: 32px;
  gap: 10px;
  cursor: pointer;
  &:hover {
    color: $blue500;
  }
}

.iconItem {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  vertical-align: middle;
}

.unscanned {
  color: $grey500;
  background-color: $grey200;
}

.infected {
  color: $red400;
  background-color: $red50;
}

.clean {
  color: $green700;
  background-color: $green100;
}

.threatsAmount {
  font-size: 22px;
  font-weight: 700;
  line-height: 140%;
  color: black;
  display: table-cell;
  vertical-align: middle;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.black {
  color: black;
}

.red {
  color: $red;
}

.green {
  color: $green500;
}

.threatsDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  display: table-cell;
  vertical-align: middle;
}
