.form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.form :global(.MuiFormHelperText-root.Mui-error) {
  font-size: 14px;
  margin-top: 10px;
}

.form :global(.MuiFormGroup-root) {
  flex-direction: row;
  /* margin-top: 16px; */
  font-size: 14px;
}
