.llFullHeight {
  height: 100vh;
}

.llContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  color: #130638;

  .llTitle {
    text-align: center;
    margin-bottom: 32px;
    font-size: 20px;
  }

  .llMessageBlock {
    margin-bottom: 32px;

    .llMessage:last-child {
      margin-bottom: 0;
    }
  }

  .llMessage {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
  }
}
