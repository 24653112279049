.Button {
  position: relative;
}

.Button:before,
.Button:after {
  content: '';
  background: var(--grey900);
  height: 2px;
  width: 12px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 2px;
  border-radius: 5px;
}

.Button:after {
  transform: rotate(90deg);
  transform-origin: center;
  transition: opacity 0.2s ease-in-out;
}

.Active:after {
  opacity: 0;
}
