@import '../../../styles/mixins';

.crumbLink {
  font-size: 14px;
  line-height: 1.5;
  color: $grey800;
  transition: 0.3s ease color;
  &:hover {
    color: $grey600;
    text-decoration: none;
  }
}
