.inner {
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: none;
  background-color: #f6f9fc;
  border-radius: 100px 100px 0 0;
  z-index: 1;
}

.hideBorder {
  border: none;
}
