.action-cards {
  &.MuiStack-root {
    max-height: 800px;
    @media (min-width: 768px) {
      // md breakpoint
      max-height: 292px;
    }

    @media (min-width: 1024px) {
      // lg breakpoint
      max-height: 520px;
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 128, 128, 0.5);
      border-radius: 4px;

      &:hover {
        background-color: rgba(128, 128, 128, 0.7);
      }
    }
  }
}
