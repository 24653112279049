.draw-day {
  padding-inline: 1px;
  min-width: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
}
.date-group {
  background-color: transparent;
  border-radius: 4px;
  box-shadow: none;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  padding: 7.5px 14px;
  font-weight: 400;
}
