.active-threats-table {
  background-color: var(--white);
  min-height: 80vh;
}

.active-threats-table th {
  padding-block: 16px;
}

.active-threats-table td :global(.idName) {
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
}
