.root {
  background-color: var(--grey50);
  border-radius: 12px;
  padding: 8px 16px;
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
}

.rootLink {
  cursor: pointer;
}
.rootLink:hover {
  color: var(--blue500);
}

.hint {
  display: flex;
  margin-bottom: 5px;
}

.root :global(.wrap-1603191783087.normalVariant) {
  margin-left: 7px;
}
