.root {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 280px;
  flex: 1;
}

.root td {
  padding: 0 0;
  text-align: center;
}

.root .firstTd {
  padding-top: 16px;
  width: 50%;
}

.link {
  cursor: pointer;
  color: var(--grey900);
}

.link:hover {
  color: var(--blue500);
}

.number {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;

  text-transform: capitalize;
}

.wrapDate {
  line-height: 30.8px;
}

.date {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;
}
.columnBorder {
  border-right: 1px solid #d3d8de;
  width: 140px;
  width: 50%;
}
