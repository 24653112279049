.container {
  display: flex;
  column-gap: 8px;
  padding-bottom: 8px;
}

.tableWrapper {
  flex: 1;
}

.wrapScale {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  min-width: 160px;
  cursor: default;
  flex-basis: 160px;
}
