.news-feed-card {
  &.MuiPaper-root {
    padding: 8px 0;
    border-radius: 4px;
    overflow: hidden;

    .dashboard-news-feed {
      &.MuiBox-root {
        border-radius: 8px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }
}
