.account-summary-table {
  max-height: calc(100vh - 650px) !important;
  height: calc(100vh - 650px) !important;
}

@media screen and (max-height: 856px) {
  .account-summary-table {
    max-height: calc(100vh - 500px) !important;
    height: calc(100vh - 500px) !important;
  }
}

@media screen and (max-height: 625px) {
  .account-summary-table {
    max-height: calc(100vh - 350px) !important;
    height: calc(100vh - 350px) !important;
  }
}

@media screen and (max-height: 532px) {
  .account-summary-table {
    max-height: calc(100vh - 250px) !important;
    height: calc(100vh - 250px) !important;
  }
}

.root :global(.no-table-data) {
  padding: 125px 0 125px;
}
