:root {
  --white: #fff;
  --black: #000;
  --grey50: #f6f9fc;
  --grey100: #eaeff3;
  --grey200: #e3e8ed;
  --grey300: #d3d8de;
  --grey400: #b2b5c1;
  --grey500: #999caa;
  --grey700: #4e5665;
  --grey800: #2f3745;
  --grey900: #1a1f28;
  --grey1000: #eaeff3;
  --yellow50: #fff7cf;
  --yellow500: #ffd56d;
  --yellow600: #ffba54;
  --yellow900: #c07300;
  --red50: #ffe7e7;
  --red400: #ff7878;
  --red500: #ed3f3f;
  --red600: #e22626;
  --red900: #9a0303;
  --blue50: #e4f7ff;
  --blue500: #32b6f3;
  --blue600: #1aa8ea;
  --green50: #ddffe5;
  --green300: #8af9a2;
  --green400: #79ed93;
  --green500: #70d180;
  --green600: #50c56a;

  --gray-500: #c1c5c9;
  --gray-600: #a1a5a9;
  /* Used in Jobs pages */
  --blue-gray: #71768e;

  /* Typography */
  --font-size-small: 14px;
  --line-height-small: 150%; /* 21px */
  --line-height-medium: 180%; /* 28.8px */

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  --button-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
