@import '../../../styles/mixins';

.topNavigationBar {
  background-color: $grey900;
  padding: 10px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0 !important;
  z-index: 10;
}

.iconTitleWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: calc(100% - 200px);

  .elastioIconWrap {
    padding-right: 12px;
  }

  .title {
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding-left: 12px;
    border-left: 1px solid white;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.groupIconWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .iconNavBarWrap {
    width: 40px;
    height: 40px;
  }
}

.userIconDivider {
  border-left: 1px solid $grey700;
  height: 28px;
}

.userImgNameWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $grey7;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  padding: 10px 16px 10px 8px;
  margin-bottom: 20px;
}

.userImg {
  border-radius: 2px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 14px;
}

.link {
  text-decoration: none;
  color: $grey7;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 8px;

  svg {
    g {
      path {
        fill: $grey500;
      }
    }

    path {
      fill: $grey500;
    }
  }
}

.link:hover {
  color: $blue500;
  text-decoration: none;

  .menuItem {
    svg {
      g {
        path {
          fill: $blue500;
        }
      }

      path {
        fill: $blue500;
      }
    }
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;

  svg {
    margin-bottom: 2px;
  }
}

.menuItem:hover {
  svg {
    g {
      path {
        fill: $blue500;
      }
    }
  }
}

.selectedMenu {
  &.menuItem {
    color: $blue500;
  }

  & .menuItem {
    color: $blue500;
  }

  svg {
    g {
      path {
        fill: $blue500;
      }
    }

    path {
      fill: $blue500;
    }
  }

  & div {
    color: $blue500;
  }
}
