@import 'styles/mixins';

.title {
  font-size: 16px;
  color: $grey900;
  line-height: 1.17;
  font-weight: 700;
  padding: 8px 16px;
  background: $grey50;
  border-radius: 12px;
  margin-bottom: 8px;
}
