.root {
  display: flex;
  width: 100%;
  margin-bottom: 0 !important;
  gap: 8px;
}

.root :global(.endLabelWrap) {
  margin-left: 0 !important;
  width: 100%;
}
