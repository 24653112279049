.root {
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
