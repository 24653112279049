.account-dialog {
  .MuiDialog-paper {
    width: 420px;
    padding: 24px;
    border-radius: 12px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 235px;
    position: absolute;
  }
}
