.styled-toggle-button-group {
  &.MuiToggleButtonGroup-root {
    display: 'flex';
    flex-direction: 'row';
    border-radius: 50px;
    .MuiToggleButtonGroup-grouped {
      border-radius: 50%;
    }
  }
}
