.resilience-score {
  &.MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9;
    opacity: 0;
    padding: 90px;
    &:hover {
      opacity: 1;
    }
  }
}
