.root {
  position: relative;
  overflow: auto;
}

.root :global(.no-table-data) {
  /* Make no data container height same as table height to avoid ugly layout changes */
  padding: 160px 0 160px;
  overflow: hidden;
}
