[data-theme='dark'] {
  .default-calendar .react-calendar__tile--range {
    background-color: #373c40 !important;

    &:enabled:hover {
      background-color: rgba(25, 118, 210, 0.15) !important;
    }
  }
}

[data-theme='light'] {
  .default-calendar .react-calendar__tile--range {
    background-color: #e4f7ff !important;

    &:enabled:hover {
      background-color: #e3e8ed !important;
    }
  }
}

.default-calendar {
  & .react-calendar {
    background: transparent;
    border: none;

    & .react-calendar__navigation {
      & button {
        min-width: 44px;
        background: none;
        font-size: 16px;
      }
    }

    & .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }

    & .react-calendar__tile {
      padding: 10px;
      background-color: transparent;
      border: none;
      border-radius: 0;

      &:enabled:hover {
        background-color: rgba(25, 118, 210, 0.15);
      }

      &:enabled:focus {
        background-color: rgba(25, 118, 210, 0.2);
      }
    }

    & .react-calendar__tile--rangeStart,
    & .react-calendar__tile--rangeEnd {
      background-color: #1976d2 !important;
      color: #ffffff !important;

      &:enabled:hover {
        background-color: #1565c0 !important;
      }
    }

    & .react-calendar__tile--rangeStart {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & .react-calendar__tile--rangeEnd {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
