.recoveryPointsTableContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.recoveryPointsDateSelector {
  display: inline-flex;
}

.recoveryPointsTableWrap {
  height: calc(100vh - 530px);
  min-height: 150px;
}
