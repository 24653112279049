.wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
}

.title {
  color: var(--grey900);
  font-weight: var(--font-weight-bold);
  font-size: 22px;
  line-height: 140%;
}

.tableCell {
  color: var(--grey900);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}
