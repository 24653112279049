.root {
  position: relative;
  height: 119px;
  display: flex;
  align-items: center;
}

.wrapScale {
  display: flex;
  min-width: 129px;
  margin-top: 20px;
  justify-content: center;
  cursor: default;
}

.scale {
  height: 138px;
  width: 16px;
  border-radius: 100px;
}

.values {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 28px;
  top: -9px;
  bottom: -9px;
}

.value {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  color: #1a1f28;
}

.dynamicValue {
  position: absolute;
  transform: translateY(-50%);
  left: 29px;
}

.wrapDivision {
  position: absolute;
  bottom: 0;
  right: 100%;
  margin-right: -26px;
}

.divisionLabel {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 4px;
  text-align: right;
  transform: translateY(-50%);
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px;
}
.divider {
  width: 27px;
}
