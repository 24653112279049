.threat-list-status {
  &.MuiBox-root {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    overflow: hidden;

    &.overflowing {
      mask-image: linear-gradient(
        to right,
        black calc(100% - 80px),
        transparent 100%
      );
    }
  }
}
