.root {
  position: relative;
  width: inherit;
  overflow: hidden;
  border-radius: 100px;
}

.gradient {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(237, 63, 63, 0.89) 0.22%,
    rgba(251, 188, 5, 0.5) 49.46%,
    #aafebd 99.82%
  );
}
