.card {
  border-radius: 8px;
  color: var(--grey900);
  font-weight: 400;
  line-height: 1.7;
  cursor: default;
}

.cardLink {
  cursor: pointer;
}

.cardLink:hover {
  color: var(--blue500);
}

.totalBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-bottom: 1px solid var(--grey300);
}

.summaryWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.summaryBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.label {
  text-align: center;
  display: inline-flex;
  align-items: center;
  gap: 7px;
}

.number {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
}

.red500 {
  color: var(--red500);
}

.entropyLayout {
  padding-top: 20px;
  padding-bottom: 20px;
}
