.container {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 20px;
}

.date {
  font-size: 11px;
  color: var(--grey500);
}

.filesWrapper {
  display: flex;
  gap: 12px;
  align-items: baseline;
}

.filesCount {
  font-size: 22px;
  font-weight: 500;
}

.filesText {
  font-size: 16px;
}

.filesDifference {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.differenceCount {
  color: var(--green600);
}

.increased {
  color: var(--red600);
}

.differenceText {
  font-size: 12px;
  color: var(--grey700);
}
