.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.backup {
  flex-direction: row;
}
