.jobs-table td {
  font-size: var(--font-size-small);
}

.jobs-table th {
  font-size: var(--font-size-small);
}

.jobs-table p {
  font-size: var(--font-size-small);
}
