@import '../../styles/mixins';

.link {
  text-decoration: none;
  color: $grey7;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 8px;

  svg {
    g {
      path {
        fill: $grey500;
      }
    }

    path {
      fill: $grey500;
    }
  }
}

.link:hover {
  color: $blue500;
  text-decoration: none;

  .menuItemIcon {
    div {
      svg {
        g {
          path {
            fill: $blue500;
          }
        }

        path {
          fill: $blue500;
        }
      }
    }
  }
}

.menuItemIcon {
  display: flex;
  flex-direction: row;
  gap: 8px;

  div {
    height: 20px;
  }
}

.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  gap: 8px;
  padding: 10px 16px 10px 8px;
  cursor: pointer;
}

.selectedMenu {
  &.menuItemIcon {
    color: $blue500;
  }

  & .menuItemIcon {
    color: $blue500;
  }

  svg {
    path {
      fill: $blue500;
    }
  }

  svg {
    g {
      path {
        fill: $blue500;
      }
    }
  }

  .baseArrowSelected {
    svg {
      path {
        fill: $blue500;
      }
    }
  }

  & div {
    color: $blue500;
  }
}

.subMenu {
  padding: 0 16px 0 30px !important;

  .menuItemIcon {
    margin-bottom: 0 !important;
  }
}
