.root {
  padding: 8px 24px 24px 24px;
}

.asset-page-tabs {
  margin: 16px 0;
}

.asset-page-tabs :global(.MuiTab-root) {
  opacity: 1;
}
