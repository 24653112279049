.root {
  position: relative;
  height: 119px;
  display: flex;
  align-items: center;
}

.scale {
  height: 138px;
  width: 16px;
  border-radius: 100px;
}

.values {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 28px;
  top: -9px;
  bottom: -9px;
  width: 67px;
}

.wrapDivision {
  position: absolute;
  bottom: 0;
  right: 100%;
  margin-right: -22px;
  transition: top 0.3s ease-in-out;
}

.divisionLabel {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 4px;
  text-align: right;
  transform: translateY(-50%);
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px;
}
.divider {
  width: 27px;
  height: 1px;
  background-color: #000;
}

.underline {
  text-decoration: underline;
}
