.cardBlock {
  background: var(--white);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
}

.security {
  grid-template-columns: 1fr;
}
