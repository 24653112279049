$fontRoboto: 'Roboto', sans-serif;
$fontCircularStd: 'Circular Std Book', 'Roboto', sans-serif;
$fontSFMono: 'SFMono-Regular', 'Menlo', 'Consolas', 'Monospace';

$white: #fff;
$black: #130638;
$lightBlack: #1c1d21;

$yellow50: #fff7cf;
$yellow200: #ffe99c;
$yellow500: #fff7cf;
$yellow600: #ffba54;
$yellow800: #e28800;
$yellow900: #c07300;

$orange: #fec53d;

$red: #ed3f3f;
$red50: #ffe7e7;
$red100: #ffd2d2;
$red300: #ffa4a4;
$red400: #ff7878;
$red500: #ed3f3f;
$red600: #e22626;
$red700: #cf1616;
$lightRed: #f9d4d6;
$redBtnBg: #eb5757;
$redBtnBdDark: #d34e4e;

$green: #71de6f;
$green50: #ddffe5;
$green100: #c4fed1;
$green500: #70d180;
$green600: #50c56a;
$green700: #45aa5b;
$green800: #319246;

$grey50: #f6f9fc;
$grey7: #f2f2f2;
$grey100: #dadbdf;
$grey200: #e3e8ed;
$grey300: #d3d8de;
$grey400: #b2b5c1;
$grey500: #999caa;
$grey600: #727883;
$grey700: #4e5665;
$grey800: #2f3745;
$grey900: #1a1f28;
$grey1000: #eaeff3;
$greyHover: #d2d7da;
$lightGray: #f6f9fc;
$averageGray: #e5e5e5;
$darkGray: #dfeaf5;

$blue1: #88bbff;
$blue2: #046af0;
$blue50: #e4f7ff;
$blue100: #cef0ff;
$blue500: #32b6f3;
$blue600: #1aa8ea;
$blue700: #0b97d8;
$whiteBlue: #33b6f2;
$lightBlue: #32b6f3;
$darkBlue: #24a1da;
$pastelBlue: #fbfcfe;
$lightViolet: #4a92fe;
$v2LightBlue: #046af0;

$purple: #916df9;
$darkPurple: #08023b;

$userStatusUndefined: #71768e;
$userStatusActive: #2cb929;
$userStatusInvited: #fec53d;
$userStatusExpired: #71768e;
$userStatusDisabled: #d82f2f;

$v2MenuDark: #1a1f28;
$v2DarkBlue: #32b6f3;
$v2Gray: #8f92a1;
$v2LightGray: #26303f;
$v3LightGray: #f5f8fa;
$v2InputBlack: #1b1f26;
$v2TextBlack: #171717;
$v2TextGray: #71768e;
$v2TextDarkGray: #1c1d21;
$v2GrayBorder: #ececf2;
$v2GrayDarkBorder: #dcdce7;
$v2GrayBorder2: #eceff1;
$v2BgGray: #f2f6fc;
$v2BgLightGray: #f5f5fa;
$v2BgDarkGray: #e3e3eb;
$v2BgLightGrayTransparent: rgba(245, 245, 250, 0.25);
$v2BgBlue: #d6f0fd;
$v2BtnDarkBlue: #113e8e;
$v2BgLightBlueTransparent: rgba($lightBlue, 0.1);

$recoveryPointSuccess: #27ae60;
$recoveryPointProgress: #27ae60;
$recoveryPointWarning: #f2994a;
$recoveryPointError: #eb5757;

$circleStatusSuccess: #27ae60;
$circleStatusProgress: #fec53d;
$circleStatusWarning: #f2994a;
$circleStatusError: #eb5757;
$circleStatusAborted: #dfeaf5;
$circleStatusNone: #dfeaf5;

$errorAlert: #ffe7e7;

$placeholderOpacity: 0.42;

$scrollBarGap: 30px;

$percentageIndicatorMinHeight: 365px;
$percentageIndicatorMinWight: 270px;

@keyframes rotate-0-180-pure {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

@mixin rotate-0-180($time: 0.2s) {
  animation-name: rotate-0-180-pure;
  animation-duration: $time;
  animation-delay: 0s;
}

@keyframes rotate-180-0-pure {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@mixin rotate-180-0($time: 0.2s) {
  animation-name: rotate-180-0-pure;
  animation-duration: $time;
  animation-delay: 0s;
}

// Progress icon animation.
@keyframes spin-progress {
  100% {
    transform: rotateZ(360deg);
  }
}

@mixin circle-spin-progress($time: 2s) {
  animation-name: spin-progress;
  animation-duration: $time;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 320;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 50;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 320;
    transform: rotate(360deg);
  }
}

@mixin circle-path-animation($time: 3s) {
  animation-name: circle-animation;
  animation-duration: $time;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}

// we can not use animation-delay because it applies opacity
// also with delaying and it leads to the blinking
@keyframes delayed-opacity-pure {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  95% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@mixin delayed-opacity($time: 1s) {
  animation-name: delayed-opacity-pure;
  animation-duration: $time;
  animation-delay: 0s;
}

@keyframes delayed-opacity-slow-pure {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@mixin delayed-opacity-slow($time: 0.2s) {
  animation-name: delayed-opacity-slow-pure;
  animation-duration: $time;
  animation-delay: 0s;
}

@keyframes smooth-opacity-pure {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@mixin smooth-opacity($time: 0.2s, $delayTime: 0s) {
  animation-name: smooth-opacity-pure;
  animation-duration: $time;
  animation-delay: $delayTime;
}

@keyframes background-smooth-opacity-pure {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.9;
  }
}

@mixin background($time: 0.2s) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 801;
  background-color: $v2MenuDark;
  opacity: 0.9;
  animation-name: background-smooth-opacity-pure;
  animation-duration: $time;
  animation-delay: 0s;
}

@keyframes background-smooth-preloader-pure {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.5;
  }
}

@mixin smooth-preloader-background($time: 0.2s) {
  animation-name: background-smooth-preloader-pure;
  animation-duration: $time;
  animation-delay: 0s;
}

@mixin mobile-h-576 {
  @media (max-height: 576px) {
    @content;
  }
}

@mixin mobile-h-768 {
  @media (max-height: 768px) {
    @content;
  }
}

@mixin mobile-h-992 {
  @media (max-height: 960px) {
    @content;
  }
}

@mixin mobile-h-1200 {
  @media (max-height: 1200px) {
    @content;
  }
}

@mixin mobile-576 {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin mobile-768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile-960 {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin mobile-1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

$resolutions: (576, 768, 960, 1200);
@each $resolution in $resolutions {
  .till-mobile-#{$resolution} {
    display: block;
  }
  .mobile-#{$resolution} {
    display: none;
  }
  @media (max-width: $resolution + 'px') {
    .mobile-#{$resolution} {
      display: block;
    }
    .till-mobile-#{$resolution} {
      display: none;
    }
    .mobile-mb0-#{$resolution} {
      margin-bottom: 0;
    }
    .mobile-mt0-#{$resolution} {
      margin-top: 0;
    }
  }
}

@mixin linkColor($color) {
  &,
  &:hover,
  &:focus,
  &:visited {
    color: $color;
  }
}

@mixin linkColorUndecorated($color) {
  &,
  &:hover,
  &:focus,
  &:visited {
    color: $color;
    text-decoration: none;
  }
}

@mixin flexAlignedRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@mixin fromButtonToLink {
  display: inline;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

@mixin hideLinkContent {
  line-height: 0;
  font-size: 0;
  color: transparent;
}

@mixin strictWidth($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

@mixin strictHeight($height) {
  height: $height;
  min-height: $height;
  max-height: $height;
}

@mixin fixOnPageTop($top: 0) {
  position: sticky;
  top: $top;
}

@mixin inputsGrayBg() {
  .MuiInputBase-root,
  .currentDayLabel,
  .rc-time-picker-input,
  .dayBtnWrap button {
    background-color: rgba(245, 245, 250, 0.25) !important;
  }
}

@mixin inputsColorFontsize($color: $v2TextDarkGray, $fontsize: 14px) {
  .MuiInputBase-root,
  .currentDayLabel,
  .rc-time-picker-input,
  .dayBtnWrap button {
    color: $color !important;
    font-size: $fontsize !important;
  }
}

@mixin contentBlockWhiteBg() {
  padding: 24px;
  background: $white;
  border: 1px solid $grey1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

@mixin setViCheckboxSize($cbSize) {
  .wrap-1618144255100 {
    &.tableVariant {
      .MuiFormControlLabel-root {
        height: $cbSize;
        margin-left: 0;
      }
    }

    .MuiCheckbox-root svg {
      width: $cbSize;
      height: $cbSize;
    }

    .MuiCheckbox-root:not(.Mui-checked) svg {
      rect {
        stroke: $grey700;
      }
    }
  }
}

@mixin rightJustifyColumns(
  $columns,
  $headers-centered: 'false',
  $p-right: 23px
) {
  @each $column in $columns {
    tbody tr {
      td:nth-child(#{$column}) {
        text-align: right;
      }
    }

    thead tr {
      th:nth-child(#{$column}) {
        @if ($headers-centered == 'false') {
          text-align: right;
          padding-right: $p-right;
        } @else {
          text-align: center;
        }
      }
    }
  }
}

@mixin tableScrollBar() {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scrollbar-width: thin;
  scrollbar-color: $grey700 transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

@mixin tableHorizontalScrollBar() {
  overflow-x: auto;
  position: absolute;
  scrollbar-width: thin;
  scrollbar-color: $grey700 transparent;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    &::-webkit-scrollbar {
      height: 10px !important;
    }
  }
}
