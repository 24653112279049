.assets-radar-chart-card {
  &.MuiPaper-root {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 10px;
    margin-bottom: 12px;
    max-width: 100%;
    @media (min-width: 640px) {
      max-width: 200px;
    }
  }
}
