.container {
  background-color: var(--white);
  font-family: Roboto;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 32px;
}

.vulnerabilities_risks {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  margin: 0;
  align-items: flex-start;
}
