.custom-schedule-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.custom-schedule-repeat {
  box-shadow: none;
  font-size: 12px;
  width: 24px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;

  &:hover {
    background: var(--blue500);
    color: #fff;
  }
}

.custom-schedule-format {
  font-size: 14px;
  margin-top: 4px;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
}
