.root {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scrollbar-width: thin;
  scrollbar-color: var(--grey700) transparent;
  max-height: 180px;
}

.root::-webkit-scrollbar {
  width: 4px;
}

.root::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
