.tenant-jobs-table {
  box-shadow: 0 0 0 1px #dfeaf5;
  font-size: 12px;
  line-height: 17px;
  max-height: calc(100vh - 190px) !important;
}

.tenant-jobs-table table {
  border-collapse: collapse;
}

.tenant-jobs-table :global(.job-failed-status-message) {
  color: red;
}

.tenant-jobs-table :global(.system-jobs-no-wrap-cell) {
  white-space: nowrap;
}

.tenant-jobs-table :global(.task-cell) {
  max-width: 300px;
  word-break: break-word;
}

/* FIXME: Cannot fix it without global, PLS help!  */
.tenant-jobs-table :global(.table-row-depth-1) td {
  border-bottom: none !important;
}

.tenant-jobs-table td {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  font-size: 12px;
  line-height: 17px;
  padding: 25px 10px;
  white-space: normal;
}

.tenant-jobs-table th {
  background-color: #f5f5fa;
  border-bottom: 1px solid #ececf2;
  color: #71768e;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-top: 15px;
}

.tenant-jobs-table tr {
  background-color: #fff;
}

.tenant-jobs-table tr:global(.table-row-depth-0):hover {
  background-color: #f6f9fc !important;
}

.tenant-jobs-table tr:nth-of-type(odd) {
  background-color: #fff;
}

.tenant-jobs-table :global(.table-row-depth-1) {
  background-color: #eceff1 !important;
  border-top: 0.5px solid var(--black);
}
